
import { DexAssessmentData } from "@/domain/entities/DexAssessment";
import { Options, prop, Vue } from "vue-class-component";

class Props {
  data = prop<any>({
    default: new DexAssessmentData(),
  });
}
@Options({
  components: {},
})
export default class NotesInformationSection extends Vue.with(Props) {}
