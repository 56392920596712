
import { DexAssessmentData } from "@/domain/entities/DexAssessment";
import { Vue, prop, Options } from "vue-class-component";
import PreviewImage from "@/app/ui/views/shipment/tracking-stt-cargo/tracking-stt-tab/component/preview-image-pod.vue";

class Props {
  data = prop<any>({
    default: new DexAssessmentData()
  });
}
@Options({
  components: { PreviewImage }
})
export default class DexInformationSection extends Vue.with(Props) {
  showPreviewImage = false;
  actionModalPreview(boolean: boolean) {
    this.showPreviewImage = boolean;
  }

  openLink(location: string) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${location}`);
  }
}
