
import { DexAssessmentData } from "@/domain/entities/DexAssessment";
import { Options, prop, Vue } from "vue-class-component";
import formatDateBasedTimezoneValue from "@/app/infrastructures/misc/common-library/FormatDateBasedTimezoneValue";

class Props {
  data = prop<any>({
    default: new DexAssessmentData()
  });
}
@Options({
  components: {}
})
export default class DetailSTTInformationSection extends Vue.with(Props) {
  formatDate(date: string): string {
    return formatDateBasedTimezoneValue(date, "HH:mm");
  }
  addressType: any = {
    home: "Rumah",
    office: "Kantor"
  };
}
