import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "gap-y-8 flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "rounded-full text-14px capitalized font-semibold px-2 py-0 w-1/5 text-center mt-1",
  style: {"background":"#ebecf0"}
}
const _hoisted_3 = {
  key: 1,
  class: "flex flex-row gap-x-2 items-center mt-1"
}
const _hoisted_4 = { class: "text-gray-lp-600 italic text-12px" }
const _hoisted_5 = {
  key: 0,
  class: "rounded-full text-14px capitalized font-semibold px-2 py-0 w-1/5 text-center mt-1",
  style: {"background":"#ebecf0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_ChipsV2 = _resolveComponent("ChipsV2")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, { label: "Informasi Tiket DEX" }),
    _createVNode(_component_DataWrapper, { label: "Status Final Assesmen" }, {
      default: _withCtx(() => [
        (!_ctx.data.daStatus)
          ? (_openBlock(), _createBlock("p", _hoisted_2, " - "))
          : (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode(_component_ChipsV2, {
                label: 
            _ctx.data.daStatus?.toLowerCase() === 'invalid' ? 'inactive' : 'active'
          ,
                title: 
            _ctx.data.daStatus?.toLowerCase() === 'invalid'
              ? 'Tidak Sesuai'
              : 'Sesuai'
          
              }, null, 8, ["label", "title"]),
              _createVNode("div", _hoisted_4, _toDisplayString(_ctx.data.daStatusDexAssessBy), 1)
            ]))
      ]),
      _: 1
    }),
    _createVNode(_component_DataWrapper, { label: "Status Sanggah" }, {
      default: _withCtx(() => [
        (!_ctx.data.daStatusDisputePartner)
          ? (_openBlock(), _createBlock("p", _hoisted_5, " - "))
          : (_openBlock(), _createBlock(_component_ChipsV2, {
              key: 1,
              class: "mt-1",
              label: _ctx.statusDisputePartner(_ctx.data.daStatusDisputePartner).label,
              title: _ctx.statusDisputePartner(_ctx.data.daStatusDisputePartner).title
            }, null, 8, ["label", "title"]))
      ]),
      _: 1
    }),
    _createVNode(_component_DataWrapper, {
      label: "Tiket ID",
      value: _ctx.data.daTicketNo
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      label: "Nama CS L1",
      value: _ctx.data.daAssignedL1Name
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      label: "Ditugaskan ke L1",
      value: _ctx.formatDate(_ctx.data.daAssignedL1Date)
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      label: "Diselesaikan ke L1",
      value: _ctx.formatDate(_ctx.data.daFinishedL1Date)
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      label: "Nama CS L2",
      value: _ctx.data.daAssignedL2Name
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      label: "Ditugaskan ke L2",
      value: _ctx.formatDate(_ctx.data.daAssignedL2Date)
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      label: "Diselesaikan ke L2",
      value: _ctx.formatDate(_ctx.data.daFinishedL2Date)
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      label: "Nama Konsol",
      value: _ctx.data.daAssignedPartnerName
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      label: "Sanggah Konsol",
      value: _ctx.formatDate(_ctx.data.daAssignedPartnerDate)
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      label: "Nama CS L2 Sanggah",
      value: _ctx.data.daAssignedL2DisputeName
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      label: "Ditugaskan ke L2 Sanggah",
      value: _ctx.formatDate(_ctx.data.daAssignedL2DisputeDate)
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      label: "Diselesaikan ke L2 Sanggah",
      value: _ctx.formatDate(_ctx.data.daFinishedL2DisputeDate)
    }, null, 8, ["value"])
  ]))
}