import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-8 my-4 gap-y-5 gap-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "p-4" }, {
    default: _withCtx(() => [
      _createVNode(_component_Title, { label: "Informasi Detail STT" }),
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_DataWrapper, {
          class: "col-span-2",
          label: "No. STT",
          value: _ctx.data.daSttNo
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "col-span-2",
          label: "Tipe Alamat",
          value: _ctx.addressType[_ctx.data.daSttRecipientAddressType]
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "col-span-2",
          label: "Waktu DEX",
          value: _ctx.formatDate(_ctx.data.daDeliveryDexDateLocal)
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "col-span-2",
          label: "Rute",
          value: `${_ctx.data.daOriginId} - ${_ctx.data.daDestinationId}`
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "col-span-2",
          label: "Jenis Pengiriman",
          value: _ctx.data.daProductType
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "col-span-2",
          label: "Percobaan DEX ",
          value: _ctx.data.daDeliveryDexAttempt
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "col-span-2",
          label: "Batas SLA",
          value: _ctx.data.daSttSla
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "col-span-2",
          label: "Sanggah Cutomer",
          value: _ctx.data.daDisputeCustomer
        }, null, 8, ["value"])
      ])
    ]),
    _: 1
  }))
}