
import { DexAssessmentData } from "@/domain/entities/DexAssessment";
import { Options, prop, Vue } from "vue-class-component";

class Props {
  data = prop<any>({
    default: new DexAssessmentData(),
  });
}
@Options({
  components: {},
})
export default class ContactInformationSection extends Vue.with(Props) {
  goToWhatsapp(phoneNumber: string) {
    const phoneFormat = phoneNumber?.replace(/\+/g, "").slice(1, phoneNumber.length);
    window.open(`https://api.whatsapp.com/send?phone=62${phoneFormat}`, "_blank");
  }
}
