
import { DexAssessmentData } from "@/domain/entities/DexAssessment";
import { Options, prop, Vue } from "vue-class-component";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";

class Props {
  data = prop<any>({
    default: new DexAssessmentData()
  });
}
@Options({
  components: {}
})
export default class DEXTicketInformationSection extends Vue.with(Props) {
  formatDate(date: string): string {
    return date ? formatDate(date) : "";
  }

  statusDisputePartner(status: string) {
    let text = { label: "", title: "" };
    switch (status.toLowerCase()) {
      case "accepted":
      case "approved":
        text = { label: "active", title: "Disetujui" };
        break;
      case "requested":
        text = { label: "waiting", title: "Pengajuan" };
        break;
      case "rejected":
        text = { label: "inactive", title: "Tidak Disetujui" };
        break;
      default:
        text = { label: "", title: "" };
        break;
    }
    return text;
  }
}
