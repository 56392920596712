import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex gap-y-4 flex-col pb-6 mr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DexInformationSection = _resolveComponent("DexInformationSection")!
  const _component_NotesInformationSection = _resolveComponent("NotesInformationSection")!
  const _component_DetailSTTInformationSection = _resolveComponent("DetailSTTInformationSection")!
  const _component_ContactInformationSection = _resolveComponent("ContactInformationSection")!
  const _component_DisputePartnerInformationSection = _resolveComponent("DisputePartnerInformationSection")!
  const _component_DEXTicketInformationSection = _resolveComponent("DEXTicketInformationSection")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    customClass: "px-0",
    onBack: _ctx.goBack,
    title: _ctx.detailData.data.daTicketNo,
    loading: _ctx.apiDetailData.loading,
    error: !!_ctx.apiDetailData.errorType,
    errorType: _ctx.apiDetailData.errorType,
    onTryAgain: _ctx.fetchDetail
  }, {
    data: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_Title, { label: "Informasi DEX" }),
        _createVNode(_component_DexInformationSection, {
          data: _ctx.detailData.data
        }, null, 8, ["data"]),
        _createVNode(_component_NotesInformationSection, {
          data: _ctx.detailData.data
        }, null, 8, ["data"]),
        _createVNode(_component_DetailSTTInformationSection, {
          data: _ctx.detailData.data
        }, null, 8, ["data"]),
        _createVNode(_component_ContactInformationSection, {
          data: _ctx.detailData.data
        }, null, 8, ["data"]),
        _createVNode(_component_DisputePartnerInformationSection, {
          data: _ctx.detailData.data
        }, null, 8, ["data"])
      ])
    ]),
    "sub-data": _withCtx(() => [
      _createVNode(_component_DEXTicketInformationSection, {
        data: _ctx.detailData.data
      }, null, 8, ["data"])
    ]),
    _: 1
  }, 8, ["onBack", "title", "loading", "error", "errorType", "onTryAgain"]))
}