
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { ApiStateData } from "@/domain/entities/Api";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { Options, Vue } from "vue-class-component";
import ContactInformationSection from "../component/detail/contact-information.vue";
import DetailSTTInformationSection from "../component/detail/detail-stt-information.vue";
import DexInformationSection from "../component/detail/dex-information.vue";
import DEXTicketInformationSection from "../component/detail/dex-ticket-information.vue";
import DisputePartnerInformationSection from "../component/detail/dispute-partner-information.vue";
import NotesInformationSection from "../component/detail/notes-infomation.vue";
import { FlagsPermissionDexAssessment } from "@/feature-flags/flags-permission-dex-assessment";

@Options({
  components: {
    DexInformationSection,
    NotesInformationSection,
    DetailSTTInformationSection,
    DisputePartnerInformationSection,
    ContactInformationSection,
    DEXTicketInformationSection
  }
})
export default class DetailDexAssesment extends Vue {
  goBack() {
    let url = "";
    if (
      FlagsPermissionDexAssessment.permission_dex_assessment_admin_enable.isEnabled()
    ) {
      url = "/dex-assessment/list-ticket-dex";
    } else if (
      FlagsPermissionDexAssessment.permission_dex_assessment_customer_consolidator_enable.isEnabled()
    ) {
      url = "/dex-assessments";
    } else {
      url = `/dex-assessment${
        this.isAgentDexL1 ? "-l1" : !this.isAgentDexL2 ? "" : "-l2"
      }`;
    }
    this.$router.push(url);
  }

  get roleType() {
    return AccountController.accountData.role_type;
  }
  get isAgentDexL1() {
    return this.roleType === "Agent DEX L1";
  }
  get isAgentDexL2() {
    return this.roleType === "Agent DEX L2";
  }

  mounted() {
    this.fetchDetail();
  }

  get id() {
    return this.$route.params.id;
  }

  apiDetailData = new ApiStateData();
  detailData = new ResponsePayloadV2();

  async fetchDetail() {
    try {
      this.apiDetailData.loading = true;
      this.detailData = await DexAssessmentController.getDetailDexAssesmentL1(
        this.id
      );
      this.apiDetailData.errorType = "";
    } catch (error) {
      this.apiDetailData.errorType =
        parsingErrorResponse(error).type === "internet-error"
          ? "internet"
          : parsingErrorResponse(error).type;
    } finally {
      this.apiDetailData.loading = false;
    }
  }
}
